import React from "react"
import { graphql } from "gatsby"
import Nav from "../components/nav"
import Footer from "../components/footer"
import { Link } from "gatsby"
import ArrowRight from "../components/icons/arrowRight"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Press1stYearAnni = ({ data,location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content
  
  return (
    <Layout>
      <Nav header="nomenu" urlParam={url_param}/>
      <div className="no-masthead relative"></div>
      <section className="section sectionFirst">
        <div className="container py-5">
          <div className="about-description">
          <br /><br />
          <h2 className="text-xl md:text-3xl xl:text-4xl font-light uppercase mb-10">A YEAR OF EXCELLENCE: BMW EUROKARS AUTO CELEBRATES FIRST YEAR
ANNIVERSARY AS THE OFFICIAL DEALER IN SINGAPORE</h2>
            <p className="mb-3 font-light"><strong>Singapore, 27 November 2023</strong></p>
            <p className="mb-5 lg:pr-5">
            BMW Eurokars Auto proudly commemorates its first-year anniversary as the official dealer of BMW in Singapore. This remarkable achievement reflects a year brimming with successes, dedication, and an unwavering commitment to achieving automotive excellence.
            </p>

            <h3 className="mb-3 text-l md:text-2xl xl:text-2xl"><strong>Unveiling a Vision</strong></h3>
            <p className="mb-5 lg:pr-5">
            BMW Eurokars Auto embarked on its journey as the official dealer in Singapore with a significant moment—the signing of the dealership appointment at the BMW Welt in Munich on 19 May 2022. With a clear vision to redefine the boundaries of automotive experience and establish new standards of excellence, the inaugural year has unfolded as a meticulous journey involving strategic planning, initiatives, and, most importantly, a celebration of the illustrious legacy of BMW.
            </p>
            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.press1
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>
            <p className="mb-5 lg:pr-5">
            In a bold and unprecedented move, BMW Eurokars Auto officially launched its partnership with BMW during the ION Orchard iPavilion Showcase on 9th September 2022. Despite the absence of a dedicated showroom, this inaugural event symbolised the dealership's enthusiastic foray into a new chapter. Demonstrating an unwavering commitment to innovation and excellence, the event showcased the BMW i4, BMW iX, and even offered guests an exclusive private preview of the luxurious BMW i7. This outstanding dedication served as a prelude to the grand opening of the dealership's first official showroom at 11 Leng Kee Road on 1st October 2022, a mere four months since Eurokars Auto’s appointment as an official BMW partner.
            </p>

            <p className="mb-5 lg:pr-5">
            Mr. Lars Nielsen, the Managing Director of BMW Group Asia, highlighted the significance of this exciting journey, stating, “At BMW, we are always very ambitious, and we want to do what is best for our customers in Singapore, which is a very important market for us. We were looking to secure the future, and to that extent, we needed to find a second partner. I’ve known Eurokars for many years already in the BMW Group, and it feels relatively easy to embark on this very exciting journey together.”
            </p>

            <h3 className="mb-3 text-l md:text-2xl xl:text-2xl"><strong>Building Lasting Connections: A Community of Enthusiasts</strong></h3>
            <p className="mb-5 lg:pr-5">
            A highlight of this community-centric philosophy was the memorable Eurokars Group Lunar New Year event hosted at Yi Qian, a Chinese restaurant renowned for its private dining experience. Together with its Indonesian Counterpart, BMW Eurokars, the dealership played host and brought together the esteemed members of BMW M Club Indonesia, BMW M Club Singapore, and BMW Car Club Singapore, marking a collaborative milestone as both countries’ BMW enthusiasts seamlessly converged under one roof. This event not only underscored Eurokars’ distinctive presence as BMW partners within the region and its status as the exclusive BMW M Dealer in Indonesia but also embodied Eurokars’ commitment to fostering a vibrant and connected community.
            </p>
            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.press2
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>
            <p className="mb-5 lg:pr-5">
            Beyond the metal and the roaring engines lies the true spirit of BMW Eurokars Auto’s success – a community of passionate BMW enthusiasts. Over the past year, the dealership has cultivated a sense of camaraderie among its customers, transcending the traditional customer-dealer relationship experience. From the exhilaration of numerous local and overseas golfing events to the culinary delights of gastronomy drives, collaborations with notable brands and professionals from various industries, and engaging showroom activations featuring craft activities and special snacks, BMW Eurokars Auto has curated a diverse array of experiences. These endeavours ensure that the bond within the BMW community extends beyond the road, creating lasting memories and connections.
            </p>

            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.press3
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>

            <h3 className="mb-3 text-l md:text-2xl xl:text-2xl"><strong>Polaris Sustainability Award</strong></h3>
            <p className="mb-5 lg:pr-5">
            The journey of excellence was further adorned with the attainment of the Polaris Sustainability Award in 2022 for both the Governance and Overall Category during the annual BMW Group Importer Conference held in Mallorca, Spain, on 31 October 2022.
            </p>

            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.press4
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>

            <p className="mb-5 lg:pr-5">
            Earlier this year, Eurokars Auto once again received the Polaris Sustainability Award in 2023 for the Social Category during the BMW Group Importer Conference held in Faro, Portugal, on 3 October 2023. These prestigious accolades recognized the dealership's consistent and outstanding efforts across all facets of sustainability.
            </p>

            <h3 className="mb-3 text-l md:text-2xl xl:text-2xl"><strong>Looking Forward: A Roadmap for the Future</strong></h3>
            <p className="mb-5 lg:pr-5">
            As BMW Eurokars Auto celebrates its one-year anniversary, it also looks ahead to the road that lies beyond. Committed to continuous improvement, innovation, and unwavering dedication to customer satisfaction, the dealership sets its sights on elevating the automotive experience even further. Plans for expansion into the Western Region of Singapore in early 2024 with a Service Factory, the introduction of state-of-the-art facilities and experience centres, and a continued focus on community engagement all contribute to the roadmap for the future.
            </p>

            <p className="mb-5 lg:pr-5">
                <GatsbyImage
                image={getImage(
                    data.allMdx.edges[0].node.frontmatter.press5
                )}
                alt={data.allMdx.edges[0].node.frontmatter.title}
                />
            </p>

            <p className="mb-5 lg:pr-5">
            The one-year anniversary of BMW Eurokars Auto as the official dealer in Singapore is not merely a celebration of time passed, but an acknowledgement of achievements, milestones, and a steadfast commitment to excellence. As the dealership reflects on a year replete with passion and progress, the stage is set for an even more exciting journey ahead, promising BMW enthusiasts in Singapore an exhilarating ride in 'Driving the Difference.’
            </p>


            <p className="text-neutral-400 hover:text-black flex float-right">
              <span className="w-5 -ml-1.5 ">
                <ArrowRight />
              </span>
              <span className="ml-1 font-bold text-black md:transition-colors md:duration-150 md:text-neutral-400 md:hover:text-black">
              <Link
                  to={"/press/" + url_param}
                  data-gtm-category="BMW Press Release"
                  data-gtm-action="Clicked_Link"
                  data-gtm-label="View Press Release">
                  <strong>Back to Press Hub</strong>
                </Link>
              </span>
            </p>
              
            <br /><br />
            
            <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <p className="mb-5 lg:pr-5">
            <strong><u>Media Enquiries</u></strong><br /><br />
            <strong>Luke Pereira</strong><br />
            Senior Marketing & Brand Communications Executive<br />
            BMW Eurokars Auto<br />
            Email: luke.pereira@eurokars.com.sg <br />
            </p>
            <br /><br />
            

            
            
          </div>
          <div className="showroom-contact mt-8 md:mt-12">
            <h2 className="text-l md:text-2xl xl:text-3xl font-light uppercase mb-3">
              Retail Locations
            </h2>
            <div className="showroom-contact-details">
              <div className="grid grid-cols-1 md:grid-cols-12 border-t md:border-0 border-neutral-200">
                <div className="md:col-span-3 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Location:
                </div>
                <div className="md:col-span-4 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Address:
                </div>
                <div className="md:col-span-5 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Opening Hours:
                </div>

                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Experience Centre</span>
                 
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  11 Kung Chong Road, Singapore 159147 
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  Monday &ndash; Saturday: 8:30am &ndash; 7pm <br />
                  Sunday & Public Holidays: 10am &ndash; 6pm 
                </div>
                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Auto Showroom</span>
                 
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  11 Leng Kee Road, Singapore 159091  
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  Monday &ndash; Saturday: 8:30am &ndash; 7pm <br />
                  Sunday & Public Holidays: 10am &ndash; 6pm 
                </div>
              </div>
            </div>
          </div>
          <div className="showroom-contact mt-8 md:mt-12">
            <h2 className="text-l md:text-2xl xl:text-3xl font-light uppercase mb-3">
              Service Locations
            </h2>
            <div className="showroom-contact-details">
              <div className="grid grid-cols-1 md:grid-cols-12 border-t md:border-0 border-neutral-200">
                <div className="md:col-span-3 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Location:
                </div>
                <div className="md:col-span-4 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Address:
                </div>
                <div className="md:col-span-5 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Opening Hours:
                </div>

                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Authorised Service Centre </span>
                  
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  11 Kung Chong Road, Singapore 159147 
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                    Monday &ndash; Friday: 8am &ndash; 6pm <br />
                    Saturday: 8am &ndash; 12pm <br />
                    Sunday & Public Holidays: Closed
                    </div>
                    
                  </div>
                  
                </div>
                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Authorised Service Centre</span>
                  
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  29 Leng Kee Road, Singapore 159099 
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                    Monday &ndash; Friday: 8am &ndash; 6pm <br />
                    Saturday: 8am &ndash; 12pm <br />
                    Sunday & Public Holidays: Closed
                    </div>
                    
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer urlParam={url_param} />
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "press_1st_year_anni" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }

            press1 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            press2 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            press3 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            press4 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            press5 {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default Press1stYearAnni

const seoDetails = {
  title: "Eurokars Auto | BMW Eurokars Press Hub - 1st Year Anniversary",
  description:
    "As an official dealer of BMW, Eurokars Auto offers the full range of BMW vehicles – from fully electric BMW i models to high-performance BMW M models.",
  keywords: "Eurokars, Eurokars Auto, BMW, BMW Eurokars Auto",
  image: "og-eka-about-us.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
