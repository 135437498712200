import * as React from "react"

const ArrowRight = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-full h-auto"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          style={{
            fill: "none",
          }}
          d="M0 0h24v24H0z"
        />
      </clipPath>
    </defs>
    <g
      style={{
        clipPath: "url(#a)",
      }}
    >
      <path
        style={{
          fill: "#4d4d4d",
          fillRule: "evenodd",
        }}
        d="M9.71 19.5H8.29l7.5-7.5-7.5-7.5h1.42l7.5 7.5-7.5 7.5z"
      />
    </g>
  </svg>
)

export default ArrowRight
